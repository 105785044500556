export const constants = {
  gameModes: {
    consecutiveDoubles: "Consecutive doubles",
    simultaneousDoubles: "Simultaneous doubles"
  },
  screens: {
    gamesList: "gamesList",
    matchmaker: "matchmaker"
  },
  playerCount: 8,
  playerStatuses: {
    available: "Available",
    holiday: "On holiday",
    injured: "Injured",
    unavailable: "Unavailable"
  },
  storageKeys: {
    bookingLinkName: "bookingLinkName",
    bookingLinkURL: "bookingLinkURL",
    currentGameNumber: "currentGame",
    gamesList: "gamesList",
    gameMode: "gameMode",
    playersList: "playersList",
    potentialPlayers: "potentialPlayers"
  }
};
