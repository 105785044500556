import React from "react";
import PropTypes from "prop-types";
import composeClassNames from "clsx";
import { ReactComponent as Chevron } from "../../assets/svgs/chevron.svg";

const Select = ({ className, currentOption, id, name, options, ...rest }) => {
  return (
    <div className="relative flex">
      <select
        className={composeClassNames(
          "h-6 appearance-none rounded bg-blue-600 px-1 pr-6 text-base font-bold text-base-100 focus:outline-none focus:ring-2 focus:ring-pink-400 sm:h-auto",
          className
        )}
        name={name}
        id={id}
        value={currentOption}
        {...rest}
      >
        {options.map((option, i) => (
          <option key={`${id}-${option.value}-${i}`} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      <div className="pointer-events-none absolute right-0 flex h-6 items-center">
        <Chevron aria-hidden="true" className="mr-1 w-3.5" />
      </div>
    </div>
  );
};

Select.propTypes = {
  className: PropTypes.string,
  currentOption: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  options: PropTypes.array.isRequired
};

Select.defaultProps = {};

export { Select };
