import React from "react";
import PropTypes from "prop-types";

const Radio = ({
  label,
  id,
  isChecked = false,
  isDisabled = false,
  name,
  onChange,
  value
}) => {
  return (
    <div className="flex">
      <input
        className="peer h-0 w-0 appearance-none"
        checked={isChecked}
        disabled={isDisabled}
        id={id}
        name={name}
        onChange={onChange}
        type="radio"
        value={value}
      />
      <label
        className="flex cursor-pointer items-center [&>svg]:peer-focus:ring-2 [&>svg]:peer-focus:ring-pink-400"
        htmlFor={id}
      >
        <svg
          aria-hidden="true"
          className={`mr-2 h-6 w-6 rounded-full outline outline-2 -outline-offset-2 transition-all duration-300 ease-in-out sm:h-5 sm:w-5 ${
            isDisabled ? "outline-base-500" : "outline-blue-600"
          } ${isChecked ? "bg-blue-600" : "bg-transparent"}`}
          viewBox="0 0 96 96"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            className={
              "ease-in-ou origin-center fill-transparent transition-all duration-300"
            }
            cx="48"
            cy="48"
            r="48"
          />
          <path
            className={`origin-center fill-base-100 transition-all duration-300 ease-in-out ${
              isChecked ? "scale-100 opacity-100" : "scale-0 opacity-0"
            }`}
            d="m68.5829 32.4575c-.9271-.9318-2.1752-1.4575-3.4825-1.4575s-2.5555.5137-3.4825 1.4575l-20.5983 20.5958-6.5968-6.678c-1.9017-1.9115-5.0278-1.9354-6.9532-.0359-1.9374 1.8995-1.9612 5.0415-.0595 7.0008l10.0793 10.2023c.9272.9318 2.1752 1.4575 3.4945 1.4575 1.3075 0 2.5436-.5137 3.4708-1.4455l24.1055-24.0843c1.9018-1.9115 1.9256-5.0535.0357-6.9888z"
          />
        </svg>

        <span>{label}</span>
      </label>
    </div>
  );
};

Radio.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
};

Radio.defaultProps = {};

export { Radio };
