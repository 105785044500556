import React from "react";
import { ReactComponent as ClipboardIcon } from "../../assets/svgs/clipboard.svg";
import { ReactComponent as CourtIcon } from "../../assets/svgs/badminton-court.svg";
import { ReactComponent as SettingsIcon } from "../../assets/svgs/settings.svg";
import { Link, useLocation } from "react-router-dom";
import { variantProps } from "classname-variants/react";
import { Toast } from "../core";

const linkProps = variantProps({
  base: "group inline-flex flex-col items-center justify-center px-5 py-2 [&>svg]:mb-1 [&>svg]:h-6 [&>svg]:w-6 focus:outline-none focus:ring-2 focus:ring-pink-400 rounded transition-colors",
  variants: {
    isCurrentTab: {
      false:
        "cursor-pointer hover:bg-blue-800 hover:text-blue-100 text-base-100",
      true: "cursor-auto text-blue-300"
    }
  }
});

const LinkButton = ({ className, href, icon, isCurrentTab, label }) => {
  return (
    <Link {...linkProps({ className, isCurrentTab })} to={href}>
      {icon}
      <span className="text-sm">{label}</span>
    </Link>
  );
};

const Navigation = ({ toastMessage, updateToast, ...rest }) => {
  let location = useLocation();
  let currentLocation = location.pathname;

  return (
    <div
      className="fixed bottom-0 left-0 z-50 flex w-full flex-col items-center"
      {...rest}
    >
      <Toast
        className="mb-2"
        message={toastMessage}
        updateMessage={updateToast}
      />
      <div className="pb-safe w-full border-t border-blue-800 bg-blue-900">
        <div className="mx-auto grid h-full max-w-lg grid-cols-3 font-medium">
          <LinkButton
            isCurrentTab={currentLocation === "/"}
            href="/"
            icon={<CourtIcon />}
            label="Play"
          />
          <LinkButton
            isCurrentTab={currentLocation === "/organise"}
            href="/organise"
            icon={<ClipboardIcon />}
            label="Organise"
          />
          <LinkButton
            isCurrentTab={currentLocation === "/settings"}
            href="/settings"
            icon={<SettingsIcon />}
            label="Settings"
          />
        </div>
      </div>
    </div>
  );
};

export { Navigation };
