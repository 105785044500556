import React from "react";
import PropTypes from "prop-types";
import composeClassNames from "clsx";

const Toast = ({ className, message, updateMessage, ...rest }) => {
  if (message) {
    setTimeout(() => updateMessage(undefined), 2000);
  }

  return (
    <div
      aria-live="polite"
      className={composeClassNames(
        `${
          message ? "px-1 opacity-100" : "px-0 opacity-0"
        } w-fit rounded bg-base-800 font-medium text-base-100 transition-opacity duration-200`,
        className
      )}
      {...rest}
    >
      {message}
    </div>
  );
};

Toast.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
  updateMessage: PropTypes.func
};

Toast.defaultProps = {};

export { Toast };
