// Local storage
export const getStringFromLocalStorage = (key) => localStorage.getItem(key);
export const setStringToLocalStorage = (key, value) =>
  localStorage.setItem(key, value);
export const getJSONFromLocalStorage = (key) =>
  JSON.parse(localStorage.getItem(key));
export const setJSONToLocalStorage = (key, value) =>
  localStorage.setItem(key, JSON.stringify(value));
export const removeItemFromLocalStorage = (key) => localStorage.removeItem(key);

// Session storage
export const getStringFromSessionStorage = (key) => sessionStorage.getItem(key);
export const setStringToSessionStorage = (key, value) =>
  sessionStorage.setItem(key, value);
export const getJSONFromSessionStorage = (key) =>
  JSON.parse(sessionStorage.getItem(key));
export const setJSONToSessionStorage = (key, value) =>
  sessionStorage.setItem(key, JSON.stringify(value));
export const removeItemFromSessionStorage = (key) =>
  sessionStorage.removeItem(key);
