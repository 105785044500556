import React, { useState } from "react";
import { Button, IconButton, PlayBar } from "..";
import { ReactComponent as Copy } from "../../assets/svgs/copy.svg";
import { ReactComponent as Shuffle } from "../../assets/svgs/shuffle.svg";

import {
  constants,
  copyConsecutiveGames,
  copySimultaneousGames,
  createNewConsecutiveGames,
  createNewSimultaneousGames,
  getStringFromLocalStorage,
  removeItemFromLocalStorage,
  setStringToLocalStorage
} from "../../utilities";

const Versus = () => <span className="px-1 text-lg font-bold">vs.</span>;

const VersusWrapper = ({ children }) => (
  <div className="flex w-full flex-col items-center justify-center gap-1">
    {children}
  </div>
);

const CourtHeading = ({ courtNumber }) => (
  <h2 className="mb-2 text-xl font-bold">Court {courtNumber}</h2>
);

const CourtWrapper = ({ children }) => (
  <div className="flex w-1/2 flex-col">{children}</div>
);

const TeamWrapper = ({ children }) => (
  <div className="flex gap-2 text-xl">{children}</div>
);

const {
  gameModes,
  screens: { matchmaker },
  storageKeys: { currentGameNumber, gamesList }
} = constants;

const GamesList = ({
  gameMode,
  games,
  players,
  setCurrentScreen,
  updateGames
}) => {
  const isConsecutiveMode = gameMode === gameModes.consecutiveDoubles;

  const [currentGame, updateCurrentGame] = useState(
    getStringFromLocalStorage(currentGameNumber)
      ? parseInt(getStringFromLocalStorage(currentGameNumber))
      : -1
  );

  const changePlayers = () => {
    //Games
    updateGames(undefined);
    removeItemFromLocalStorage(gamesList);

    const nextGameNumber = -1;
    updateCurrentGame(nextGameNumber);
    setStringToLocalStorage(currentGameNumber, nextGameNumber);

    // Screen
    setCurrentScreen(matchmaker);
  };

  const shuffleGames = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    const nextGameNumber = -1;
    isConsecutiveMode
      ? createNewConsecutiveGames(players, updateGames)
      : createNewSimultaneousGames(players, updateGames);
    updateCurrentGame(nextGameNumber);
    setStringToLocalStorage(currentGameNumber, nextGameNumber);
  };

  return (
    <>
      {isConsecutiveMode && (
        <PlayBar
          currentGame={currentGame}
          games={games}
          setCurrentScreen={setCurrentScreen}
          updateCurrentGame={updateCurrentGame}
        />
      )}
      {isConsecutiveMode
        ? games.map((game, index) => {
            const isCurrentGame = currentGame === index;
            const shouldFade = currentGame > index;
            return (
              <div
                className={`flex flex-col gap-2 rounded-2xl border-2 p-2 transition-all duration-300 ${
                  isCurrentGame ? "border-yellow-500" : "border-transparent"
                } ${shouldFade ? "opacity-80" : "opacity-1"}`}
                key={`game-${index}`}
                id={`game-${index}`}
              >
                <h2 className="text-2xl font-bold">Game {index + 1}</h2>
                <div className="flex items-end gap-2">
                  <CourtWrapper>
                    <CourtHeading courtNumber={1} />
                    <VersusWrapper>
                      <TeamWrapper>
                        <span>{game.court1.team1[0]}</span>
                        <span>&</span>
                        <span>{game.court1.team1[1]}</span>
                      </TeamWrapper>
                      <Versus />
                      <TeamWrapper>
                        <span>{game.court1.team2[0]}</span>
                        <span>&</span>
                        <span>{game.court1.team2[1]}</span>
                      </TeamWrapper>
                    </VersusWrapper>
                  </CourtWrapper>
                  <hr className="h-[5.625rem] border border-blue-800" />
                  <CourtWrapper>
                    <CourtHeading courtNumber={2} />
                    <VersusWrapper>
                      <TeamWrapper>
                        <span>{game.court2.team1[0]}</span>
                        <span>&</span>
                        <span>{game.court2.team1[1]}</span>
                      </TeamWrapper>
                      <Versus />
                      <TeamWrapper>
                        <span>{game.court2.team2[0]}</span>
                        <span>&</span>
                        <span>{game.court2.team2[1]}</span>
                      </TeamWrapper>
                    </VersusWrapper>
                  </CourtWrapper>
                </div>
              </div>
            );
          })
        : games.map((game, index) => (
            <div
              className="flex flex-col gap-2 rounded-2xl p-2 transition-all duration-300"
              key={`game-${index}`}
              id={`game-${index}`}
            >
              <h2 className="text-2xl font-bold">Game {index + 1}</h2>
              <div className="flex justify-center gap-2">
                <CourtWrapper>
                  <VersusWrapper>
                    {game.length === 4 && (
                      <>
                        <TeamWrapper>
                          <span>{game[0]}</span>
                          <span>&</span>
                          <span>{game[1]}</span>
                        </TeamWrapper>
                        <Versus />
                        <TeamWrapper>
                          <span>{game[2]}</span>
                          <span>&</span>
                          <span>{game[3]}</span>
                        </TeamWrapper>
                      </>
                    )}
                    {game.length === 3 && (
                      <>
                        <TeamWrapper>
                          <span>{game[0]}</span>
                          <span>&</span>
                          <span>{game[1]}</span>
                        </TeamWrapper>
                        <Versus />
                        <TeamWrapper>
                          <span>{game[2]}</span>
                        </TeamWrapper>
                      </>
                    )}
                    {game.length <= 2 && (
                      <>
                        <TeamWrapper>
                          <span>{game[0]}</span>
                          {game[1] && (
                            <>
                              <span className="text-lg font-bold">vs.</span>
                              <span>{game[1]}</span>
                            </>
                          )}
                        </TeamWrapper>
                      </>
                    )}
                  </VersusWrapper>
                </CourtWrapper>
              </div>
            </div>
          ))}
      <div className="mt-4 mb-28 flex justify-center gap-2">
        <Button
          onClick={() => changePlayers()}
          text="Change players"
          variant="secondary"
        />
        <IconButton
          id="edit-players-button"
          onClick={() =>
            isConsecutiveMode
              ? copyConsecutiveGames(games)
              : copySimultaneousGames(games)
          }
          screenReaderText="Copy"
        >
          <Copy aria-hidden="true" />
        </IconButton>
        <IconButton
          id="shuffle-players-button"
          onClick={() => shuffleGames()}
          screenReaderText="Shuffle"
        >
          <Shuffle aria-hidden="true" />
        </IconButton>
      </div>
    </>
  );
};

export { GamesList };
