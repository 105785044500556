import React from "react";

const Error = ({ ...rest }) => {
  return (
    <div className="flex min-h-screen flex-col items-center p-4 pt-2 text-center text-base-100">
      <span
        className="flex rounded border-pink-700 bg-pink-700 p-2 text-pink-100"
        {...rest}
      >
        Whoops, page not found!
      </span>
    </div>
  );
};

export { Error };
