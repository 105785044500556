import React, { useState } from "react";
import { Button, Radio, Shell } from "../components";
import {
  constants,
  getStringFromLocalStorage,
  removeItemFromLocalStorage,
  setStringToLocalStorage
} from "../utilities";

const { gameModes, storageKeys } = constants;

const Settings = () => {
  const [chosenBookingLinkName, updateChosenBookingLinkName] = useState(
    getStringFromLocalStorage(storageKeys.bookingLinkName) || null
  );
  const [gameMode, updateGameMode] = useState(
    getStringFromLocalStorage(storageKeys.gameMode) ||
      gameModes.consecutiveDoubles
  );
  const [chosenBookingLinkURL, updateChosenBookingLinkURL] = useState(
    getStringFromLocalStorage(storageKeys.bookingLinkURL) || null
  );
  const [potentialPlayers, updatePotentialPlayers] = useState(
    getStringFromLocalStorage(storageKeys.potentialPlayers) || null
  );

  const resetBookingLink = () => {
    removeItemFromLocalStorage(storageKeys.bookingLinkName);
    removeItemFromLocalStorage(storageKeys.bookingLinkURL);
    updateChosenBookingLinkName(null);
    updateChosenBookingLinkURL(null);
  };

  const resetPotentialPlayers = () => {
    removeItemFromLocalStorage(storageKeys.potentialPlayers);
    updatePotentialPlayers(null);
  };

  const handleUpdateGameMode = (newMode) => {
    updateGameMode(newMode);
    setStringToLocalStorage(storageKeys.gameMode, newMode);
    removeItemFromLocalStorage(storageKeys.gamesList);
  };

  return (
    <Shell className="gap-2">
      <div>
        <h2 className="mb-2 text-xl font-bold">Mode</h2>
        <div className="flex flex-col gap-2">
          <Radio
            label={gameModes.consecutiveDoubles}
            id="consecutive-doubles-radio"
            isChecked={gameMode === gameModes.consecutiveDoubles}
            name="mode-radio"
            onChange={() => handleUpdateGameMode(gameModes.consecutiveDoubles)}
            value={gameModes.consecutiveDoubles}
          />
          <Radio
            label={gameModes.simultaneousDoubles}
            id="simultaneous-doubles-radio"
            isChecked={gameMode === gameModes.simultaneousDoubles}
            name="mode-radio"
            onChange={() => handleUpdateGameMode(gameModes.simultaneousDoubles)}
            value={gameModes.simultaneousDoubles}
          />
        </div>
      </div>
      <div>
        <h2 className="mb-2 text-xl font-bold">Reset</h2>
        <div className="flex flex-col gap-2">
          <Button
            isDisabled={
              chosenBookingLinkName === null || chosenBookingLinkURL === null
            }
            id="reset-booking-link-button"
            onClick={() => resetBookingLink()}
            text="Reset booking link"
            variant="secondary"
          />
          <Button
            isDisabled={potentialPlayers === null}
            id="reset-potential-players-button"
            onClick={() => resetPotentialPlayers()}
            text="Reset players"
            variant="secondary"
          />
        </div>
      </div>
    </Shell>
  );
};

export { Settings };
