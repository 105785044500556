import React from "react";
import PropTypes from "prop-types";
import { variantProps } from "classname-variants/react";

const inputProps = variantProps({
  base: "w-full appearance-none rounded bg-blue-800 text-base font-semibold text-base-100 hover:bg-blue-700 focus:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-pink-500",
  variants: {
    size: {
      small: "px-2",
      standard: "px-3 py-1"
    },
    defaultVariants: {
      size: "standard"
    }
  }
});

const Input = ({ className, id, label, size = "standard", ...rest }) => {
  return (
    <div>
      <label className="sr-only" htmlFor={id}>
        {label}
      </label>
      <input
        id={id}
        placeholder={label}
        {...inputProps({ className, size })}
        {...rest}
      />
    </div>
  );
};

Input.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  size: PropTypes.oneOf(["small", "standard"])
};

export { Input };
