import { constants } from "./constants";
import { setJSONToLocalStorage } from "./storageHandler";

const {
  storageKeys: { gamesList }
} = constants;

export const shuffleArray = (array) => {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex]
    ];
  }

  return array;
};

export const createNewConsecutiveGames = (players, updateGames) => {
  const shuffledPlayers = shuffleArray([...players]);

  const games = [
    {
      court1: {
        team1: [shuffledPlayers[0], shuffledPlayers[1]],
        team2: [shuffledPlayers[6], shuffledPlayers[7]]
      },
      court2: {
        team1: [shuffledPlayers[4], shuffledPlayers[5]],
        team2: [shuffledPlayers[2], shuffledPlayers[3]]
      }
    },
    {
      court1: {
        team1: [shuffledPlayers[0], shuffledPlayers[2]],
        team2: [shuffledPlayers[1], shuffledPlayers[3]]
      },
      court2: {
        team1: [shuffledPlayers[4], shuffledPlayers[6]],
        team2: [shuffledPlayers[5], shuffledPlayers[7]]
      }
    },
    {
      court1: {
        team1: [shuffledPlayers[0], shuffledPlayers[3]],
        team2: [shuffledPlayers[5], shuffledPlayers[6]]
      },
      court2: {
        team1: [shuffledPlayers[4], shuffledPlayers[7]],
        team2: [shuffledPlayers[1], shuffledPlayers[2]]
      }
    },
    {
      court1: {
        team1: [shuffledPlayers[0], shuffledPlayers[4]],
        team2: [shuffledPlayers[3], shuffledPlayers[7]]
      },
      court2: {
        team1: [shuffledPlayers[1], shuffledPlayers[5]],
        team2: [shuffledPlayers[2], shuffledPlayers[6]]
      }
    },
    {
      court1: {
        team1: [shuffledPlayers[0], shuffledPlayers[6]],
        team2: [shuffledPlayers[2], shuffledPlayers[4]]
      },
      court2: {
        team1: [shuffledPlayers[1], shuffledPlayers[7]],
        team2: [shuffledPlayers[3], shuffledPlayers[5]]
      }
    },
    {
      court1: {
        team1: [shuffledPlayers[0], shuffledPlayers[7]],
        team2: [shuffledPlayers[5], shuffledPlayers[2]]
      },
      court2: {
        team1: [shuffledPlayers[1], shuffledPlayers[6]],
        team2: [shuffledPlayers[3], shuffledPlayers[4]]
      }
    },
    {
      court1: {
        team1: [shuffledPlayers[0], shuffledPlayers[5]],
        team2: [shuffledPlayers[1], shuffledPlayers[4]]
      },
      court2: {
        team1: [shuffledPlayers[2], shuffledPlayers[7]],
        team2: [shuffledPlayers[3], shuffledPlayers[6]]
      }
    }
  ];
  shuffleArray(games);

  updateGames(games);
  setJSONToLocalStorage(gamesList, games);
};

export const createNewSimultaneousGames = (players, updateGames) => {
  const shuffledPlayers = shuffleArray([...players]);

  const games = [];
  while (shuffledPlayers.length) {
    games.push(shuffledPlayers.splice(0, 4));
  }

  updateGames(games);
  setJSONToLocalStorage(gamesList, games);
};

export const copyConsecutiveGames = (games) => {
  let gamesString = games.map(
    (game, index) =>
      `Game ${index + 1}
Court 1
${game.court1.team1[0]} & ${game.court1.team1[1]}
vs.
${game.court1.team2[0]} & ${game.court1.team2[1]}

Court 2
${game.court2.team1[0]} & ${game.court2.team1[1]}
vs.
${game.court2.team2[0]} & ${game.court2.team2[1]}

`
  );
  navigator.clipboard.writeText(gamesString);
};

export const copySimultaneousGames = (games) => {
  let gamesString = games.map((game, index) => {
    let gameString;

    if (game.length === 4) {
      gameString = `${game[0]} & ${game[1]}
vs.
${game[2]} & ${game[3]}`;
    } else if (game.length === 3) {
      gameString = `${game[0]} & ${game[1]}
vs.
${game[2]}`;
    } else if (game.length === 2) {
      gameString = `${game[0]} vs. ${game[1]}`;
    } else if (game.length === 1) {
      gameString = game[0];
    }

    return `Game ${index + 1}
${gameString}

`;
  });
  navigator.clipboard.writeText(gamesString);
};

export const copyPlayingPlayers = (potentialPlayers) => {
  const playingPlayers = potentialPlayers.filter((player) => player.isPlaying);
  const playingPlayerNames = playingPlayers.map((player) => player.name);

  navigator.clipboard.writeText(playingPlayerNames.join("\n"));
};
