import React, { useState } from "react";
import { BookingLink, PlayersList, Shell } from "../components";

const Organise = () => {
  const [toastMessage, updateToast] = useState(undefined);

  return (
    <Shell
      className="gap-4"
      toastMessage={toastMessage}
      updateToast={updateToast}
    >
      <BookingLink />
      <PlayersList updateToast={updateToast} />
    </Shell>
  );
};

export { Organise };
