import React, { useState } from "react";
import { Button, Input, Select } from "../core";
import { ReactComponent as Copy } from "../../assets/svgs/copy.svg";
import { ReactComponent as Delete } from "../../assets/svgs/trash.svg";
import { ReactComponent as Edit } from "../../assets/svgs/edit.svg";
import {
  constants,
  copyPlayingPlayers,
  getJSONFromLocalStorage,
  setJSONToLocalStorage
} from "../../utilities";
import { IconButton, PlayerStatusCheckbox } from "../";

const { playerStatuses, storageKeys } = constants;

const isPlayerOut = (status) => status !== playerStatuses.available;

const PlayersList = ({ updateToast }) => {
  // State
  const [isEditing, updateIsEditing] = useState(false);
  const [potentialPlayers, updatePotentialPlayers] = useState(
    getJSONFromLocalStorage(storageKeys.potentialPlayers) || []
  );
  const [typedName, updateTypedName] = useState("");

  // Functions
  const removePlayer = (i) => {
    const currentList = [...potentialPlayers];
    currentList.splice(i, 1);

    updatePotentialPlayers(currentList);
    setJSONToLocalStorage(storageKeys.potentialPlayers, currentList);
  };

  const handleAddPotentialPlayerNameChange = (e) => {
    const inputValue = e.target.value;
    updateTypedName(inputValue);
  };

  const handlePotentialPlayerPlayingChange = (e, i) => {
    const currentList = [...potentialPlayers];

    currentList[i].isPlaying = !currentList[i].isPlaying;

    updatePotentialPlayers(currentList);
    setJSONToLocalStorage(storageKeys.potentialPlayers, currentList);
  };

  const handlePotentialPlayerStatusChange = (e, i) => {
    const currentList = [...potentialPlayers];
    const newStatus = e.target.value;

    currentList[i].status = newStatus;

    if (isPlayerOut(newStatus)) {
      currentList[i].isPlaying = false;
    }

    updatePotentialPlayers(currentList);
    setJSONToLocalStorage(storageKeys.potentialPlayers, currentList);
  };

  const handlePotentialPlayerNameChange = (e, i) => {
    const currentList = [...potentialPlayers];
    const newName = e.target.value;

    currentList[i].name = newName;

    updatePotentialPlayers(currentList);
    setJSONToLocalStorage(storageKeys.potentialPlayers, currentList);
  };

  const copyOnClick = () => {
    copyPlayingPlayers(potentialPlayers);
    updateToast("Copied");
  };

  const handleOnSubmit = (e) => {
    const currentList = [...potentialPlayers];
    currentList.push({
      name: typedName,
      status: "Available",
      isPlaying: false
    });

    updatePotentialPlayers(currentList);
    setJSONToLocalStorage(storageKeys.potentialPlayers, currentList);

    updateTypedName("");

    e.preventDefault();
  };

  const potentialPlayersTotal = potentialPlayers.filter(
    (player) => player.isPlaying
  ).length;

  return (
    <div className="flex flex-col gap-2">
      <div className="flex items-center gap-1">
        <h2 className="text-xl font-bold">Players</h2>
        {potentialPlayersTotal > 0 && (
          <span className="mt-[1px] flex h-6 w-6 items-center justify-center rounded-full bg-blue-600 text-sm font-bold sm:h-[22px] sm:w-[22px]">
            {potentialPlayersTotal}
          </span>
        )}
        {isEditing ? (
          <Button
            className="!px-1"
            id="finished-edit-players-button"
            onClick={() => updateIsEditing(!isEditing)}
            size="small"
            text="Done"
            variant="secondary"
          />
        ) : (
          <>
            <IconButton
              className="pt-[0.1875rem] pr-[0.1875rem]"
              id="edit-players-button"
              onClick={() => copyOnClick()}
              screenReaderText="Copy playing players"
              size="small"
            >
              <Copy aria-hidden="true" />
            </IconButton>
            <IconButton
              className="pt-[0.1875rem] pr-[0.1875rem]"
              id="edit-players-button"
              onClick={() => updateIsEditing(!isEditing)}
              screenReaderText="Edit"
              size="small"
            >
              <Edit aria-hidden="true" />
            </IconButton>
          </>
        )}
      </div>
      {isEditing ? (
        <form
          className="flex w-full flex-col gap-2"
          id="potential-players-form"
          name="potential-players-form"
          onSubmit={(e) => handleOnSubmit(e)}
        >
          <table className="w-fit">
            <tbody>
              {potentialPlayers.map((player, i) => {
                const isOut = isPlayerOut(player.status);
                return (
                  <tr key={`potential-player-${i}-row`}>
                    <td className="flex pl-0 pb-2 pr-2 pt-0">
                      <PlayerStatusCheckbox
                        label={player.name}
                        id={`${player.name}-${i}-checkbox`}
                        isChecked={!!player.isPlaying}
                        isDisabled={isOut}
                        isOut={isOut}
                        name={`${player.name}-${i}-checkbox`}
                        onChange={(e) =>
                          handlePotentialPlayerPlayingChange(e, i)
                        }
                        playerStatus={player.status}
                      />
                      <Input
                        id={`potential-player-${i}-name-input`}
                        label="Name"
                        onChange={(e) => handlePotentialPlayerNameChange(e, i)}
                        size="small"
                        value={player.name}
                      />
                    </td>
                    <td className="px-0 pb-2 pt-0">
                      <Select
                        aria-label={`${player.name} status`}
                        className={`${isOut ? "!bg-orange-600" : ""}`}
                        currentOption={player.status}
                        id={`${player.name}-${i}-status-select`}
                        name={`${player.name}-${i}-status`}
                        options={[
                          {
                            label: playerStatuses.available,
                            value: "Available"
                          },

                          { label: playerStatuses.injured, value: "Injured" },
                          {
                            label: playerStatuses.holiday,
                            value: playerStatuses.holiday
                          },
                          {
                            label: playerStatuses.unavailable,
                            value: "Unavailable"
                          }
                        ]}
                        onChange={(e) =>
                          handlePotentialPlayerStatusChange(e, i)
                        }
                      />
                    </td>
                    <td className="px-0 pb-2 pt-0">
                      <span className="ml-2 flex">
                        <button
                          className="rounded-sm text-red-500 ring-offset-2 ring-offset-blue-900 focus:outline-none focus:ring-2 focus:ring-pink-400"
                          id={`${player.name}-${i}-delete-button`}
                          onClick={() => removePlayer(i)}
                          type="button"
                        >
                          <span className="sr-only">Delete</span>
                          <Delete aria-hidden="true" height="20px" />
                        </button>
                      </span>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <Input
            id="add-potential-player-input"
            label="Name"
            onChange={(e) => handleAddPotentialPlayerNameChange(e)}
            value={typedName}
          />
          <Button
            id="add-potential-player-button"
            isDisabled={typedName === ""}
            text="Add"
            type="submit"
          />
        </form>
      ) : (
        <table className="w-fit">
          <tbody>
            {potentialPlayers.map((player, i) => {
              const isOut = isPlayerOut(player.status);
              return (
                <tr key={`${player.name}-${i}-row`}>
                  <td className="pl-0 pb-2 pr-2 pt-0">
                    <PlayerStatusCheckbox
                      label={player.name}
                      id={`${player.name}-${i}-checkbox`}
                      isChecked={!!player.isPlaying}
                      isDisabled={isOut}
                      isOut={isOut}
                      name={`${player.name}-${i}-checkbox`}
                      onChange={(e) => handlePotentialPlayerPlayingChange(e, i)}
                      playerStatus={player.status}
                      showLabel
                    />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
    </div>
  );
};

export { PlayersList };
