import React, { useState } from "react";
import { Button, IconButton, Input } from "../../components";
import {
  constants,
  getStringFromLocalStorage,
  setStringToLocalStorage
} from "../../utilities";

const {
  storageKeys: { bookingLinkName, bookingLinkURL }
} = constants;
import { ReactComponent as Edit } from "../../assets/svgs/edit.svg";

const BookingLink = () => {
  // State
  const [isEditing, updateIsEditing] = useState(false);
  const [showInput, updateShowInput] = useState(false);

  const [chosenBookingLinkName, updateChosenBookingLinkName] = useState(
    getStringFromLocalStorage(bookingLinkName) || undefined
  );
  const [chosenBookingLinkURL, updateChosenBookingLinkURL] = useState(
    getStringFromLocalStorage(bookingLinkURL) || undefined
  );

  const [typedBookingLinkName, updateTypedBookingLinkName] = useState(
    getStringFromLocalStorage(bookingLinkName) || ""
  );
  const [typedBookingLinkURL, updateTypedBookingLinkURL] = useState(
    getStringFromLocalStorage(bookingLinkURL) || ""
  );

  // Functions
  const handleLinkNameChange = (e) => {
    const inputValue = e.target.value;
    updateTypedBookingLinkName(inputValue);
  };

  const handleLinkURLChange = (e) => {
    const inputValue = e.target.value;
    updateTypedBookingLinkURL(inputValue);
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    setStringToLocalStorage(bookingLinkName, typedBookingLinkName);
    setStringToLocalStorage(bookingLinkURL, typedBookingLinkURL);

    updateChosenBookingLinkName(typedBookingLinkName);
    updateChosenBookingLinkURL(bookingLinkURL);

    updateIsEditing(false);
  };

  const onEditClick = () => {
    updateIsEditing(true);
    updateShowInput(true);
  };

  // Constants
  const hasBookingLink = !!chosenBookingLinkURL && !!chosenBookingLinkName;

  return (
    <div className="flex flex-col gap-1">
      <div className="flex items-center gap-1">
        <h2 className="text-xl font-bold">Book</h2>
        {!isEditing && hasBookingLink && (
          <IconButton
            className="pt-[0.1875rem] pr-[0.1875rem]"
            id="edit-booking-link-button"
            onClick={() => onEditClick()}
            screenReaderText="Edit"
            size="small"
          >
            <Edit aria-hidden="true" />
          </IconButton>
        )}
      </div>
      <form
        className="flex w-full flex-col gap-2"
        id="booking-form"
        name="booking-form"
        onSubmit={(e) => handleOnSubmit(e)}
      >
        {(!hasBookingLink || isEditing) && (
          <>
            {!showInput && (
              <Button
                id="add-booking-link-button"
                onClick={() => updateShowInput(true)}
                text="Add booking link"
              />
            )}
            {showInput && (
              <>
                <Input
                  id="booking-name-input"
                  label="Name"
                  onChange={(e) => handleLinkNameChange(e)}
                  value={typedBookingLinkName}
                />
                <Input
                  id="booking-link-input"
                  label="URL"
                  onChange={(e) => handleLinkURLChange(e)}
                  value={typedBookingLinkURL}
                />
                <Button
                  isDisabled={
                    typedBookingLinkName === "" || typedBookingLinkURL === ""
                  }
                  id="submit-booking-link-button"
                  text="Done"
                  type="submit"
                />
              </>
            )}
          </>
        )}

        {!isEditing && (
          <a
            className="w-fit rounded text-blue-300 underline ring-offset-2 ring-offset-blue-900 focus:outline-none focus:ring-2 focus:ring-pink-400"
            href={chosenBookingLinkURL}
            target="_blank"
            rel="noreferrer"
          >
            {chosenBookingLinkName}
          </a>
        )}
      </form>
    </div>
  );
};

export { BookingLink };
