import React, { useState } from "react";
import {
  constants,
  getJSONFromLocalStorage,
  getStringFromLocalStorage
} from "../utilities";
import { GamesList, Matchmaker, Shell } from "../components";

const { screens, gameModes, storageKeys } = constants;

const Play = () => {
  const [gameMode] = useState(
    getStringFromLocalStorage(storageKeys.gameMode) ||
      gameModes.consecutiveDoubles
  );

  const [games, updateGames] = useState(
    getJSONFromLocalStorage(storageKeys.gamesList) || undefined
  );

  const [currentScreen, setCurrentScreen] = useState(
    getJSONFromLocalStorage(storageKeys.gamesList)
      ? screens.gamesList
      : screens.matchmaker
  );

  const [players, updatePlayers] = useState(
    getJSONFromLocalStorage(storageKeys.playersList) || []
  );

  return (
    <Shell className="text-center">
      {currentScreen === screens.matchmaker && (
        <Matchmaker
          gameMode={gameMode}
          players={players}
          setCurrentScreen={setCurrentScreen}
          updateGames={updateGames}
          updatePlayers={updatePlayers}
        />
      )}
      {currentScreen === screens.gamesList && (
        <GamesList
          gameMode={gameMode}
          games={games}
          players={players}
          setCurrentScreen={setCurrentScreen}
          updateGames={updateGames}
          updatePlayers={updatePlayers}
        />
      )}
    </Shell>
  );
};

export { Play };
