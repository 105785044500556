import React from "react";
import PropTypes from "prop-types";
import { variantProps } from "classname-variants/react";

const IconButton = ({
  children,
  className,
  screenReaderText,
  size,
  ...rest
}) => {
  const buttonProps = variantProps({
    base: "appearance-none items-center rounded bg-blue-800 text-blue-200 flex justify-center cursor-pointer focus:outline-none focus:ring-2 focus:ring-pink-400",
    variants: {
      size: {
        small: "p-1 [&>svg]:w-5",
        standard: "p-2 [&>svg]:w-6"
      },
      defaultVariants: {
        size: "standard"
      }
    },
    compoundVariants: [
      {
        variants: {
          variant: "primary"
        },
        className:
          "bg-blue-600 border-blue-600 hover:bg-blue-700 hover:border-blue-700"
      }
    ]
  });

  return (
    <button type="button" {...buttonProps({ className, size })} {...rest}>
      <span className="sr-only">{screenReaderText}</span>
      {children}
    </button>
  );
};

IconButton.propTypes = {
  /** Icon passed as child content. */
  children: PropTypes.node,
  /** Styles override. */
  className: PropTypes.string,
  /** Handler invoked when the button is clicked. */
  onClick: PropTypes.func,
  /** Screen reader text. */
  screenReaderText: PropTypes.string.isRequired,
  /** Button size. */
  size: PropTypes.oneOf(["small", "standard"])
};

IconButton.defaultProps = {
  size: "standard"
};

export { IconButton };
