import React from "react";
import { constants, setStringToLocalStorage } from "../../utilities";
import { Button } from "../core";
import { ReactComponent as Play } from "../../assets/svgs/play.svg";
import { ReactComponent as Stop } from "../../assets/svgs/stop.svg";
import { ReactComponent as Restart } from "../../assets/svgs/restart.svg";
import { ReactComponent as ArrowUp } from "../../assets/svgs/arrow-up.svg";
import { ReactComponent as ArrowDown } from "../../assets/svgs/arrow-down.svg";

const {
  storageKeys: { currentGameNumber }
} = constants;

const PlayBarButton = ({ ...rest }) => {
  return (
    <Button
      className="flex min-h-[2rem] min-w-[2rem] items-center justify-center !rounded-full !border-green-600 !bg-green-600 hover:!border-green-700 hover:!bg-green-700"
      {...rest}
    />
  );
};

const PlayBar = ({ currentGame, updateCurrentGame }) => {
  const startPlaying = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });

    const element = document.getElementById("game-0");
    element.scrollIntoView({ behavior: "smooth", block: "center" });

    updateCurrentGame(0);
    setStringToLocalStorage(currentGameNumber, 0);
  };

  const stopPlaying = () => {
    updateCurrentGame(-1);
    setStringToLocalStorage(currentGameNumber, -1);
  };

  const updateGame = (currentGame, isIncrease) => {
    const nextGameNumber = isIncrease ? currentGame + 1 : currentGame - 1;

    const element = document.getElementById(`game-${nextGameNumber}`);
    element.scrollIntoView({ behavior: "smooth", block: "center" });

    updateCurrentGame(nextGameNumber);
    setStringToLocalStorage(currentGameNumber, nextGameNumber);
  };

  return (
    <div className="pb-safe fixed bottom-[4.5rem] left-0 z-10 flex w-full justify-end px-2">
      <div className="rounded-full bg-green-800 p-2">
        <div className="flex gap-2">
          {currentGame > -1 && (
            <PlayBarButton
              aria-label="Stop playing"
              onClick={() => stopPlaying()}
            >
              <Stop height="16px" />
            </PlayBarButton>
          )}
          <PlayBarButton
            aria-label={currentGame < 1 ? "Start playing" : "Restart"}
            isDisabled={currentGame === 0}
            onClick={() => startPlaying()}
          >
            {currentGame < 0 ? (
              <Play height="16px" />
            ) : (
              <Restart height="16px" />
            )}
          </PlayBarButton>
          {currentGame >= 0 && (
            <div className="flex gap-2 border-l border-l-green-900 pl-2">
              <PlayBarButton
                aria-label="Previous game"
                isDisabled={currentGame <= 0}
                onClick={() => updateGame(currentGame)}
              >
                <ArrowUp height="16px" />
              </PlayBarButton>
              <PlayBarButton
                aria-label="Next game"
                isDisabled={currentGame === 6}
                onClick={() => updateGame(currentGame, true)}
              >
                <ArrowDown height="16px" />
              </PlayBarButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { PlayBar };
