import React from "react";
import ReactDOM from "react-dom";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import "./index.css";
import { Error, Organise, Play, Settings } from "./screens";
import * as serviceWorker from "./serviceWorker";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Play />,
    errorElement: <Error />
  },
  {
    path: "organise",
    element: <Organise />
  },
  {
    path: "settings",
    element: <Settings />
  }
]);

ReactDOM.render(
  <RouterProvider router={router} />,
  document.getElementById("root")
);

serviceWorker.unregister();
