import React from "react";
import PropTypes from "prop-types";
import { variantProps } from "classname-variants/react";

const Button = ({
  children,
  className,
  isDisabled,
  size,
  text,
  variant,
  ...rest
}) => {
  const buttonProps = variantProps({
    base: "appearance-none border items-center rounded text-base text-base-100 flex font-bold justify-center cursor-pointer max-w-max focus:outline-none focus:ring-2 focus:ring-pink-400",
    variants: {
      size: {
        small: "px-2",
        standard: "py-1 px-2"
      },
      isDisabled: {
        true: "cursor-not-allowed"
      },
      defaultVariants: {
        size: "standard",
        variant: "primary"
      }
    },
    compoundVariants: [
      {
        variants: {
          variant: "primary",
          isDisabled: false
        },
        className:
          "bg-blue-600 border-blue-600 hover:bg-blue-700 hover:border-blue-700"
      },
      {
        variants: {
          isDisabled: true,
          variant: "primary"
        },
        className: "bg-purple-700 border-purple-700"
      },
      {
        variants: {
          isDisabled: false,
          variant: "secondary"
        },
        className:
          "text-blue-400 border-blue-400 hover:border-blue-500 hover:text-blue-500"
      },
      {
        variants: {
          isDisabled: true,
          variant: "secondary"
        },
        className: "text-purple-400 border-purple-400"
      },
      {
        variants: {
          isDisabled: false,
          variant: "tertiary"
        },
        className:
          "text-blue-300 underline bg-transparent border-transparent hover:text-blue-400"
      }
    ]
  });

  return (
    <button
      disabled={isDisabled}
      type="button"
      {...buttonProps({ className, isDisabled, size, variant })}
      {...rest}
    >
      {text || children}
    </button>
  );
};

Button.propTypes = {
  /** Child content. */
  children: PropTypes.node,
  /** Styles override. */
  className: PropTypes.string,
  /** Sets disabled state. */
  isDisabled: PropTypes.bool,
  /** Handler invoked when the button is clicked. */
  onClick: PropTypes.func,
  /** Button size. */
  size: PropTypes.oneOf(["small", "standard"]),
  /** Text displayed in the button. */
  text: PropTypes.string,
  /** Button display variant. */
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary"])
};

Button.defaultProps = {
  isDisabled: false,
  size: "standard",
  variant: "primary"
};

export { Button };
