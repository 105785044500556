import React from "react";
import composeClassNames from "clsx";

import { ReactComponent as AdmintonLogo } from "../../assets/svgs/adminton.svg";
import { Navigation } from "../application";

const Shell = ({ children, className, toastMessage, updateToast, ...rest }) => {
  return (
    <div
      className="min-safe-h-screen flex flex-col items-center p-4 pt-2 text-base-100"
      {...rest}
    >
      <header>
        <h1 className="sr-only">Adminton</h1>
        <AdmintonLogo
          className="mx-4 mt-2 mb-6 text-4xl font-bold"
          height="30px"
        />
      </header>
      <div
        className={composeClassNames(
          "flex w-full max-w-lg flex-col",
          className
        )}
      >
        {children}
      </div>
      <Navigation toastMessage={toastMessage} updateToast={updateToast} />
    </div>
  );
};

export { Shell };
