import React from "react";
import { Button } from "..";
import {
  constants,
  createNewConsecutiveGames,
  createNewSimultaneousGames,
  removeItemFromLocalStorage,
  setJSONToLocalStorage
} from "../../utilities";

const { gameModes, screens, storageKeys } = constants;

const clearInputs = (updatePlayers) => {
  updatePlayers([]);
  removeItemFromLocalStorage(storageKeys.playersList);
};

const filterPlayers = (players) =>
  players.map((player) => player.trim()).filter((player) => player !== "");

const handleOnSubmit = (
  e,
  isConsecutiveMode,
  players,
  setCurrentScreen,
  updateGames,
  updatePlayers
) => {
  e.preventDefault();

  const filteredPlayers = filterPlayers(players);

  updatePlayers(filteredPlayers);
  setJSONToLocalStorage(storageKeys.playersList, filteredPlayers);

  isConsecutiveMode
    ? createNewConsecutiveGames(filteredPlayers, updateGames)
    : createNewSimultaneousGames(filteredPlayers, updateGames);

  setCurrentScreen(screens.gamesList);
};

const Matchmaker = ({
  gameMode,
  players,
  setCurrentScreen,
  updateGames,
  updatePlayers
}) => {
  const isConsecutiveMode = gameMode === gameModes.consecutiveDoubles;

  const handlePlayersChange = (e) => {
    const blockText = e.target.value;
    const playersArray = blockText.split("\n");

    updatePlayers(playersArray);
    setJSONToLocalStorage(storageKeys.playersList, playersArray);
  };

  const filteredPlayers = filterPlayers(players);

  const hasTooManyPlayers =
    isConsecutiveMode && filteredPlayers.length > constants.playerCount;

  return (
    <form
      className="flex w-full flex-col items-center"
      id="players-form"
      name="players-form"
      onSubmit={(e) =>
        handleOnSubmit(
          e,
          isConsecutiveMode,
          players,
          setCurrentScreen,
          updateGames,
          updatePlayers
        )
      }
    >
      <div className="flex w-full flex-col gap-2">
        <textarea
          className="w-full rounded bg-blue-800 p-2 font-medium text-base-100 focus:outline-none focus:ring-2 focus:ring-pink-500"
          id="players-list-text-area"
          name="players-list-text-area"
          rows="16"
          placeholder="Enter player names separated by a new line"
          onChange={(e) => handlePlayersChange(e)}
          value={players.join("\n")}
        />
        <div className="flex justify-between gap-2 px-1">
          <span className="rounded-full bg-blue-800 px-2">
            <span className="font-bold">Mode: </span>
            <span className="whitespace-nowrap">{gameMode}</span>
          </span>
          <span className="rounded-full bg-blue-800 px-2">
            <span className="font-bold">Players: </span>
            <span
              className={`${
                hasTooManyPlayers ? "font-bold text-orange-400" : ""
              }`}
            >
              {filteredPlayers.length}
            </span>
            {isConsecutiveMode && <span>/{constants.playerCount}</span>}
          </span>
        </div>
      </div>
      <div className="mt-4 flex gap-4">
        <Button
          id="clear-button"
          onClick={() => clearInputs(updatePlayers)}
          isDisabled={!players.some((value) => value !== "")}
          text="Clear"
          variant="secondary"
        />
        <Button
          id="submit-button"
          isDisabled={
            isConsecutiveMode
              ? filteredPlayers.length !== 8
              : filteredPlayers.length < 4
          }
          text="Team up"
          type="submit"
        />
      </div>
    </form>
  );
};

Matchmaker.defaultProps = {};

export { Matchmaker };
